<template>
  <div id="golfCourse" class="container">
    <b-row class="infoRow showDesk">
      <b-col>
        <a href="javascript:history.back()" class="aBacklink">
          <p class="arrowBack">
            <font-awesome-icon class="icon arrowIcon" :icon="['fa', 'chevron-left']" />
            Back to All Destinations
          </p>
        </a>
      </b-col>
    </b-row>
    <div class="splash">
      <b-img class="sImg" :src="course[0].acf.top_banner_image"></b-img>
      <h1 v-html='course[0].title.rendered' class="sTitle">
      </h1>
    </div>
    <div class="gradHome"></div>
    <b-container class="allInfo">
      <b-row class="icons" style='position:relative;'>
        <b-col lg='2' md='2' cols='12'>
          <b-img class="barLogo" :src="course[0].acf.destination_logo"></b-img>
        </b-col>
        <div class="pipeDestinations"></div>
        <b-col lg='10' md='10' cols='12'>
          <b-row>
            <b-col class="factCenter"> 
              <b-img
                class="fact"
                :src="config.VUE_APP_LOGO + '2020/11/AsianTour_HOLES.png'"
              ></b-img>
            </b-col>
            <b-col class="factCenter">
              <b-img
                class="fact"
                :src="config.VUE_APP_LOGO + '2020/11/AsianTour_RESTUARTANTS.png'"
              ></b-img>
            </b-col>
            <b-col class="factCenter">
              <b-img
                class="fact"
                :src="config.VUE_APP_LOGO + '2020/11/AsianTour_ACCOMODATION.png'"
              ></b-img>
            </b-col>
            <b-col class="factCenter">
              <b-img
                class="fact"
                :src="config.VUE_APP_LOGO + '2020/11/AsianTour_GOLF_FACILITIES.png'"
              ></b-img>
            </b-col>
            <b-col class="factCenter">
              <b-img
                class="fact"
                :src="config.VUE_APP_LOGO + '2020/11/AsianTour_OTHER.png'"
              ></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="factCenter">
              {{ course[0].acf.destinations_icon_holes }}
            </b-col>
            <b-col class="factCenter">
              {{ course[0].acf.destinations_icon_restaurant }}
            </b-col>
            <b-col class="factCenter">
              {{ course[0].acf.destinations_icon_accommodation }}
            </b-col>
            <b-col class="factCenter">
              <span v-html="course[0].acf.destinations_icon_facilities"></span>
            </b-col>
            <b-col class="factCenter">
              {{ course[0].acf.destinations_icon_other }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="infoRow hideMob">
        <b-col>
          <a href="javascript:history.back()" class="aBacklink">
            <p class="arrowBack">
              <font-awesome-icon class="icon arrowIcon" :icon="['fa', 'chevron-left']" />
              Back to All Destinations
            </p>
          </a>
        </b-col>
      </b-row>
      <b-row class="infoRow goldClub-info">
        <b-col lg='9' md='12' cols='12'>
          <h3 class="titleDistaIndiv">
            <span v-html="course[0].acf.title"></span>
          </h3>
          <span v-html="course[0].acf.content_area"></span>
        </b-col>
        <b-col class="col-md-3 col-12">
          <b-row class="courseRightSided">
            <b-col>
              <h4 class="subTitle CourseSubTitle">Courses</h4>
              <span v-html="course[0].acf.course_info"></span>
            </b-col>
          </b-row>
          <b-row class="operationalRow">
            <b-col>
              <h4 class="subTitle">
                Operational Hours
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span v-html="course[0].acf.operations_info"></span>
            </b-col>
          </b-row>
          <b-row class="soicalIcons">
            <b-col v-if="course[0].acf.facebook_link !== null" class="col-md-2">
              <b-nav-item :href="course[0].acf.facebook_link">
                <font-awesome-icon
                  class="icon subTitle"
                  :icon="['fab', 'facebook-square']"
                />
              </b-nav-item>
            </b-col>
            <b-col v-if="course[0].acf.twitter_link !== ''" class="col-md-2">
              <b-nav-item :href="course[0].acf.twitter_link">
                <font-awesome-icon
                  class="icon subTitle"
                  :icon="['fab', 'twitter']"
                />
              </b-nav-item>
            </b-col>
            <b-col v-if="course[0].acf.instagram_link !== ''" class="col-md-2">
              <b-nav-item :href="course[0].acf.instagram_link">
                <font-awesome-icon
                  class="icon subTitle"
                  :icon="['fab', 'instagram']"
                />
              </b-nav-item>
            </b-col>
            <b-col v-if="course[0].acf.global_link !== ''" class="col-md-2">
              <b-nav-item :href="course[0].acf.global_link">
                <font-awesome-icon
                  class="icon subTitle"
                  :icon="['fa', 'globe']"
                />
              </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="photoTitle-section">
        <b-col>
          <h3 class="photogalleyTitle">
            Photo Gallery
          </h3>
        </b-col>
      </b-row>
      <b-row class="photoTitle-section">
        <!-- <span v-html="course[0].acf.slider_area"></span> -->
        <!-- First Row -->
        <b-col col lg="4">
          <div v-b-modal.modal-lg class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_one + ')' }">
          </div>
          <div class="gradHome"></div>
          <b-modal id="modal-lg" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_one">
          </b-modal>
        </b-col>
        <b-col col lg="4">
          <div v-b-modal.modal-lg-1 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_two + ')' }">
          </div>
          <div class="gradHome"></div>
          <b-modal id="modal-lg-1" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_two">
          </b-modal>
        </b-col>
        <b-col col lg="4">
          <div v-b-modal.modal-lg-2 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_three + ')' }">
          </div>
          <div class="gradHome"></div>
          <b-modal id="modal-lg-2" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_three">
          </b-modal>
        </b-col>
        <!-- Second Row -->
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_four.length > 0">
          <div v-b-modal.modal-lg-3 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_four + ')' }">
          </div>
          <div class="gradHome"></div>  
          <b-modal id="modal-lg-3" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_four">
          </b-modal>
        </b-col>
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_five.length > 0">
          <div v-b-modal.modal-lg-4 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_five + ')' }">
          </div>
          <div class="gradHome"></div>          
          <b-modal id="modal-lg-4" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_five">
          </b-modal>
        </b-col>
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_six.length > 0">
          <div v-b-modal.modal-lg-5 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_six + ')' }">
          </div>
          <div class="gradHome"></div> 
          <b-modal id="modal-lg-5" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_six">
          </b-modal>
        </b-col>
        <!-- Third Row -->
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_seven.length > 0">
          <div v-b-modal.modal-lg-6 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_seven + ')' }">
          </div>
          <div class="gradHome"></div> 
          <b-modal id="modal-lg-6" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_seven">
          </b-modal>
        </b-col>
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_eight.length > 0">
          <div v-b-modal.modal-lg-7 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_eight + ')' }">
          </div>
          <div class="gradHome"></div> 
          <b-modal id="modal-lg-7" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_eight">
          </b-modal>
        </b-col>
        <b-col col lg="4" class="marginTop-Gallery" v-show="course[0].acf.slider_image_nine.length > 0">
          <div v-b-modal.modal-lg-8 class="sliderBKimage" v-bind:style="{ backgroundImage: 'url(' + course[0].acf.slider_image_nine + ')' }">
          </div>
          <div class="gradHome"></div> 
          <b-modal id="modal-lg-8" size="lg" :title="course[0].title.rendered">
            <img class="sliderImage" :src="course[0].acf.slider_image_nine">
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "golfCourse",
  props: ["id", 'config'],
  data() {
    return {
      course: []
    };
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/pages?slug=" +
          this.id
      ) // Page number changed on each page based on JSON page id you are calling
      .then(response => {
        this.course = response.data;
      });
  }
};
</script>

<style scoped>
.aBacklink {
  text-decoration: none;
}
.operationalRow {
  margin-top: 30px;
}
h4.subTitle.CourseSubTitle {
  margin-bottom: 50px;
}
.row.courseRightSided {
  margin-top: 40px;
}
::v-deep .nav-link {
  /* display: block; */
  padding: 0;
}
::v-deep .arrowIcon{
  font-size: 13px!important;
  margin-right: 10px;
}
.marginTop-Gallery {
  margin-top: 25px;
}
::v-deep .modal {
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
::v-deep .modal-footer {
  display: none;
}
.sliderImage {
  width: 100%;
}
.sliderBKimage {
  height: 300px;
  /* border-radius: 15px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.photoTitle-section {
  padding-top: 2%;
  padding-bottom: 2%;
}
h3.photogalleyTitle {
  color: #0A3F7F;
  display: inline-block;
  font-size: 20px!important;
  font-weight: 500;
}
.soicalIcons {
  float: left;
  position: relative;
}
.goldClub-info {
  margin-top: -6em;
}
#golfCourse {
  padding-bottom: 8%;
  /* background-color: #f1f1f1; */
}
.splash {
  /* height: 600px; */
  overflow: hidden;
}
.sImg {
  width: 100%;
  /* transform: translateY(-15%); */
  max-height: 500px;
  margin-top: 50px;
}
.allInfo {
  padding-top: 3%;
}
.barLogo {
  /* width: 100%; */
  width: 130px;
  margin-left: 20px;
  position: relative;
  top: -10px;
}
.fact {
  width: 50px;
  text-align: center;
  margin: auto;
  display: block;
}
.factCenter {
  color: grey;
  text-align: center;
  font-size: 14px;
}
.pipeDestinations {
  background: grey;
  width: 1px;
  height: 50px;
  position: absolute;
    left: 185px;
}
.icons {
  border-radius: 15px;
  padding-top: 2%;
  /* background-color: white; */
}
.infoRow {
  padding-top: 4%;
  padding-bottom: 4%;
}
::v-deep p {
  font-size: 14px;
  color: #707070;
}
::v-deep li {
  font-size: 14px;
  color: #707070;
}
::v-deep u {
  color: #0A3F7F;
  font-size: 14px;
  text-decoration: none;
}
.titleDistaIndiv {
  color: #0A3F7F;
  display: inline-block;
  font-size: 20px!important;
  padding: 5% 0;
  font-weight: 500;
}
.arrowBack {
  color: #0A3F7F;
  font-size: 14px;
}
.arrowBack:hover {
  color: #41a2f6;
  font-size: 14px;
  text-decoration: none;
}
.subTitle {
  color: #0A3F7F;
  font-size: 20px;
}
::v-deep strong {
  font-weight: bolder;
  color: #0A3F7F!important;
}
::v-deep .aigpl-hide {
  display: none;
}
::v-deep .aigpl-img-caption {
  display: none;
}
::v-deep .aigpl-gallery {
  display: inline-table;
}
::v-deep .aigpl-img {
  border-radius: 15px;
  box-shadow: none !important;
  border: 0;
  margin: 0;
  padding: 0;
  object-fit: cover;
  object-position: top center;
  height: 100%;
  width: 100%;
}
::v-deep .aigpl-img-wrp {
  position: relative;
  height: 300px;
  width: 100%;
}
::v-deep .aigpl-slider-slide {
  padding: 0 5px !important;
  text-align: center;
  width: 30%;
  display: inherit;
}
::v-deep .aigpl-inr-wrp {
  width: 300px;
  padding-bottom: 10%;
}
.svg-inline--fa {
  font-size: 16px;
}
.sTitle {
  position: absolute;
  background-color: rgba(1, 86, 153, 0.8);
  /* padding: 2%; */
  color: white;
  /* bottom: 11.7em; */
  z-index: 999;
  margin-top: -5.2em;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background-color: rgba(10, 63, 127, 1)!important;
  width: 300px;
  padding-top: 20px;
  padding-bottom: 20px
}
::v-deep .alignright, img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
}
.gradHome {
  background-color: #98C556;
  background-image: linear-gradient(to right, #98C556 , #BCDD8D);
  height: 10px;
  /* width: 96%;
  position: absolute; */
  top: 190px;
}
@media (min-width: 992px) {
  ::v-deep .modal-lg, .modal-xl {
    max-width: 1000px;
  }
  .showDesk {
    display: block;
  }
}
@media only screen and (max-width: 990px) {
  .hideMob {
    display: none;
  }
  .sTitle {
    padding-top: 10px;
    padding-bottom: 10px
  }
  .factCenter{
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
  }
  .sliderBKimage {
    height: 300px;
    /* border-radius: 15px; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
  }
  .photoTitle-section  {
    padding-top: 2%;
    padding-bottom: 2%;
    display: block;
  }
  .sTitle{
    position: relative;
    top: 0%;
    font-size: 12px;
  }
  .splash {
    height: 435px;
  }
  .pipeDestinations {
    display: none;
  }
  .barLogo {
    margin-left: 0px;
  }
  .goldClub-info {
    margin-top: 2em;
  }
  .icons>div{
    text-align: center;
  }
  .soicalIcons {
    float: none;
    position: relative;
    padding-bottom: 50px;
  }
  .sImg {
    width: 100%;
    /* transform: translateY(-15%); */
    max-height: 500px;
    margin-top: 0px;
  }
  .soicalIcons {
    top: -1.4em;
    padding-bottom: 0px;
  }
  ::v-deep h4.subTitle.CourseSubTitle {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .splash {
    height: 235px;
  }
  .barLogo{
    top: -3px;
  }
}
</style>
